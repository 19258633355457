/* eslint-disable-next-line sort-imports/sort-imports */
import "styles/site.scss";

import { SailingOverview } from "interfaces/cruise";
import { ProductType, UserTypes } from "interfaces/enums";
import { attachHeaderClickTracking, GA4EventName, trackEvent, trackPageRedirect } from "services/analytics";
import { getUserInfo, virtuosoUser } from "services/auth/user-info";
import { enableMegaMenu } from "services/layout/mega-menu";
import { setCanonicalUrl } from "services/layout/metadata";
import { enableDestinationNavSearch, enableHotelsNavSearch, loadPopularDestinations, setBodyClasses } from "services/layout/navigation";
import { buildCobrandLogos, cobrandLinks, HeaderHtmlType, HeaderSearchType, initProfile, initSharedHeader, ProfileAccountType, trackGA4Event, type GA4TrackingEvent, type GA4TrackingItem } from "virtuoso-shared-web-ui";
import { getDashboardUrl } from "services/layout/environment";
import { getFeatureFlag, initializeFeatureFlags } from "services/feature-flags";


// These globals are defined in cshtml files
declare global {
    interface Window {
        VIRTUOSO: { // Namespace for "globals". Nested objects defined in Razor pages will be referenced, not copied, but this shouldn't be an issue
            apimURI?: string;
            articleKey?: string; // Articles/Index.cshtml
            cmsBaseUrl?: string; // _Layout.cshtml, pulled from appsettings
            consumerMSALAuthority?: string;
            consumerMSALClientId?: string;
            consumerMSALKnownAuthority?: string;
            consumerMSALRedirectURI?: string;
            consumerMSALScope?: string;
            destinationKey?: string; // Destinations/Index.cshtml and Inspiration.cshtml
            dynamicPageKey?: string; // campaign/Index.cshtml
            enterpriseServicesUrl?: string; // _Layout.cshtml, pulled from appsettings
            ga4ItemId?: string; // An Id set during the initial 'item_view' event and is intended to be used to link multiple event types to an item
            ga4PageCategory?: string; // All/most cshtml pages to set consistent category names for page groups used in GA4
            hideHeader?: boolean; // Prevents shared header stuff from running
            hotelBrandName?: string; // Hilton specific hotel brand name
            isEmbeddedMode?: boolean; // _Layout.cshtml, if true, url query requested with Embedded=1
            jobNumberKey?: string; // tte/Index.cshtml
            languageId?: number; // Localized Landing Page, others in the future?
            ldClientId?: string; // _Layout.cshtml, LaunchDarkly client ID
            dashboardUrl?: string; // _Layout.cshtml, NDE MyDashboard Url
            partnerNameKey?: string; // tte/Index.cshtml
            productId?: number; // Search catalogs & detail pages
            productType?: ProductType; // luxury-*/Index.cshtml -- also search catalogs & detail pages
            sailingOverviewData?: SailingOverview; // luxury-cruises/Sailings.cshtml
            serverUTCMS?: number; // _Layout.cshtml, for reliable JS date calculations
            shipId?: number; // luxury-cruises/Ships.cshtml
            signOutUrl?: string; // _Layout.cshtml, pulled from appsettings
            suppressHearts?: boolean; // _Layout.cshtml, if true, wanderlist.ts > enableHearts won't run
            supplierId?: number; // Brand pages (cruise and tour)
            topicKey?: string; // Inspiration.cshtml
            trackEvent: (eventName: GA4EventName, eventData: GA4TrackingItem | GA4TrackingItem[]) => void;
            trackGA4Event: (ga4Event: GA4TrackingEvent) => void;
            user?: string; // _Layout.cshtml, pulled from server side
            userEmail?: string;
            weglotApiKey?: string; // _Layout.cshtml weglot initialize api
            wlLandingPage?: string; // wanderlist/index.cshtml -- which dotCMS page to grab
        };
    }
}

// WARNING: Be cautious wrapping this in feature flags or promises of any kind. Downstream code depends on things
// initialized inside here. Ye be warned.
getUserInfo(); // Parse user info

// Handle if object already has properties added from Razor pages
window.VIRTUOSO = {
    ...window.VIRTUOSO,
    trackGA4Event: trackGA4Event,
    trackEvent: trackEvent
};

initializeFeatureFlags().then(() => {
    const shouldUseCustomLanguageSwitcher = getFeatureFlag("consumer-2025-03-custom-language-switcher-temp") as boolean;


    if (!window.VIRTUOSO.hideHeader) {
        initSharedHeader({
            enableLanguageTranslation: shouldUseCustomLanguageSwitcher ?? false,
            headerHtmlType: HeaderHtmlType.StaticCobranded,
            headerSearchType: HeaderSearchType.HasInput,
            languageTranslationApiKey: window.VIRTUOSO.weglotApiKey,
            useKendoAutoComplete: false,
            useLocationFromIp: true,
            userType: (virtuosoUser.userAccountType || "").toLowerCase() as ProfileAccountType
        });
        attachHeaderClickTracking();
        enableDestinationNavSearch();
        enableHotelsNavSearch();
        loadPopularDestinations();

        buildCobrandLogos(virtuosoUser.cobrandModel);
        initProfile({
            accountType: virtuosoUser.userAccountType || "Anonymous",
            changePasswordUrl: `${window.VIRTUOSO.enterpriseServicesUrl}/LogIn/ResetPassword?ReturnUrl=${encodeURIComponent(window.location.href)}`,
            companyMeid: virtuosoUser.companyMasterEntityId,
            dashboardUrl: getDashboardUrl(),
            firstName: virtuosoUser.userFirstName,
            isLoggedIn: (virtuosoUser.isLoggedIn) ? true : false,
            isNewAccount: (virtuosoUser.isNewLogin) ? true : false,
            lastLoggedInAs: virtuosoUser.lastLoggedInAs,
            lastName: virtuosoUser.userLastName,
            meId: virtuosoUser.userMasterEntityId || virtuosoUser.federatedUserId, // Consumers use federatedUserId and have a null userMasterEntityId
            regionId: virtuosoUser.userRegionId,
            signInUrl: `${window.VIRTUOSO.enterpriseServicesUrl}/LogIn/SignIn.aspx?ReturnUrl=${encodeURIComponent(window.location.href)}`,
            signOutUrl: `${window.VIRTUOSO.signOutUrl}&returnPageUrl=${encodeURIComponent(window.location.href)}`,
            sourceSystem: "Consumer Site",
            userType: (virtuosoUser.userAccountType || UserTypes.NotSet).toLowerCase() as ProfileAccountType
        });
    }

    setCanonicalUrl();
    setBodyClasses();
    cobrandLinks(document.body); // Deal with the initially drawn links (header & footer)
    enableMegaMenu();

    trackPageRedirect();
});
